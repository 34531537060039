aside {
  overflow-y: scroll;
}

aside::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 1024px) {
  main {
    width: 100% !important;
  }
}

@media screen and (min-width: 1024px) {
  .App {
    width: 100%;
    display: flex;
  }

  aside {
    width: 23% !important;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgb(var(--color-secondary));
  }

  main {
    width: 77% !important;
  }
}

@media screen and (min-width: 1200px) {
  aside {
    width: 20% !important;
  }

  main {
    width: 80% !important;
  }
}
